import Vue from 'vue'
import axios from './utils/http.js';
import ElementUI from 'element-ui';
import './assets/css/index.css';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import { router } from './router';
import report from '@/utils/report';
import VueClipboard from 'vue-clipboard2'

Vue.use(ElementUI);
Vue.use(VueClipboard);
Vue.http = Vue.prototype.$http = axios;
Vue.config.productionTip = false
Vue.report = Vue.prototype.$report = report;

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
