const getViewerConf = function () {
  let config = {
    appContainer: document.body,
    mainContainer: document.getElementById('viewerContainer'),
    viewerContainer: document.getElementById('viewer'),
    eventBus: null,
    toolbar: {
      // container: document.getElementById('toolbarViewer'),
      // numPages: document.getElementById('numPages'),
      // pageNumber: document.getElementById('pageNumber'),
      // scaleSelectContainer: document.getElementById('scaleSelectContainer'),
      // scaleSelect: document.getElementById('scaleSelect'),
      // customScaleOption: document.getElementById('customScaleOption'),
      // previous: document.getElementById('previous'),
      // next: document.getElementById('next'),
      // zoomIn: document.getElementById('zoomIn'),
      // zoomOut: document.getElementById('zoomOut'),
      // viewFind: document.getElementById('viewFind'),
      // viewBookmark: document.getElementById('viewBookmark')
    },
    sidebar: {
      // Divs (and sidebar button)
      outerContainer: document.getElementById('outerContainer'),
      viewerContainer: document.getElementById('viewerContainer'),
      // thumbnailView: document.getElementById('thumbnailView'),
      outlineView: document.getElementById('outlineView'),
      // attachmentsView: document.getElementById('attachmentsView')s
    },
    sidebarResizer: {
      outerContainer: document.getElementById('outerContainer'),
      // resizer: document.getElementById('sidebarResizer')
    },
    findBar: {
      bar: document.getElementById('findbar'),
      toggleButton: document.getElementById('viewFind'),
      findField: document.getElementById('findInput'),
      highlightAllCheckbox: document.getElementById('findHighlightAll'),
      caseSensitiveCheckbox: document.getElementById('findMatchCase'),
      entireWordCheckbox: document.getElementById('findEntireWord'),
      findMsg: document.getElementById('findMsg'),
      findResultsCount: document.getElementById('findResultsCount'),
      findPreviousButton: document.getElementById('findPrevious'),
      findNextButton: document.getElementById('findNext')
    },
    passwordOverlay: {
      overlayName: 'passwordOverlay',
      container: document.getElementById('passwordOverlay'),
      label: document.getElementById('passwordText'),
      input: document.getElementById('password'),
      submitButton: document.getElementById('passwordSubmit'),
      cancelButton: document.getElementById('passwordCancel')
    },
    documentProperties: {
      overlayName: 'documentPropertiesOverlay',
      container: document.getElementById('documentPropertiesOverlay'),
      closeButton: document.getElementById('documentPropertiesClose'),
      fields: {
        fileName: document.getElementById('fileNameField'),
        fileSize: document.getElementById('fileSizeField'),
        title: document.getElementById('titleField'),
        author: document.getElementById('authorField'),
        subject: document.getElementById('subjectField'),
        keywords: document.getElementById('keywordsField'),
        creationDate: document.getElementById('creationDateField'),
        modificationDate: document.getElementById('modificationDateField'),
        creator: document.getElementById('creatorField'),
        producer: document.getElementById('producerField'),
        version: document.getElementById('versionField'),
        pageCount: document.getElementById('pageCountField'),
        pageSize: document.getElementById('pageSizeField'),
        linearized: document.getElementById('linearizedField')
      }
    },
    errorWrapper: {
      container: document.getElementById('errorWrapper'),
      errorMessage: document.getElementById('errorMessage'),
      closeButton: document.getElementById('errorClose'),
      errorMoreInfo: document.getElementById('errorMoreInfo'),
      moreInfoButton: document.getElementById('errorShowMore'),
      lessInfoButton: document.getElementById('errorShowLess')
    },
    printContainer: document.getElementById('printContainer'),
    openFileInputName: 'fileInput',
    debuggerScriptPath: './debugger.js'
  }
  return config
}
export { getViewerConf }
