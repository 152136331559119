<template>
  <div id="app">
      <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
    
  }
}
</script>

<style scoped>
.el-container{
  height: 100%;
  padding: 0;
  -webkit-app-region: no-drag;
}
#app{
  height: 100%;
  overflow: hidden;
  background-color: #f6f7fb;
  position: relative;
}
</style>
