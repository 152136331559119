<template>
  <div id="pdf-viewer-box" ref="pdfViewerBox" v-loading="loading">
    <div id="dialog-menu-box" ref="dialogMenuBox" v-show="dialogMenuBoxShow">
      <div class="dialog-menu translate" @click.stop="translateText">翻译</div>
      <div class="dialog-menu annotation" @click.stop="judgeSelectionExistAnno">批注</div>
      <div class="dialog-menu copy" @click.stop="copySelected">复制</div>
    </div>
    <div id="dialog-translate-box" ref="dialogTranslateBox" v-show="dialogTranslateBoxShow">
      <div>{{selectionText}}</div>
      <div>{{autoTranslation}}</div>
    </div>
    <div class="outline-btn" @click="openDocumentoutline">目录</div>
    <div v-if="hasSearch" class="outline-btn search-btn" @click="findKeyword">搜索</div>
    <div id="outerContainer">
      <div id="sidebarContainer" style="display: none;">
        <div id="sidebarContent" style="margin-top:30px">
          <div id="outlineView"></div>
        </div>
      </div>
      <div id="mainContainer">
        <div id="viewerContainer" ref="viewerContainer" tabindex="0" :style="{left: '0px'}">
          <div ref="pdfContainer" id="viewer" class="pdfViewer">
          </div>
        </div>
      </div>
      <div class="annotations-list">
        <el-drawer 
          :visible.sync="showAnnotionList"
          :direction="drawerDirection"
          size="80%"
          ref="annotationDrawer"
          :with-header="false">
            <div class="drawer-hearder">
              <div class="hearder-first"></div>
              <div class="drawer-title">
                <span class="title-text">批注</span>
              </div>
              <div class="drawer-close">
                <div class="drawer-close-icon" @click="closeDrawer"></div>
              </div>
              <div class="hearder-last"></div>
            </div>
            <div class="drawer-body">
              <div class="anno-origin-text">
                <div class="origin-text-operation">
                  <div class="font1">原文</div>
                  <div class="switch-annotations">
                    <div class="select-left" @click="previousAnnotation"></div>
                    <div class="select-right" @click="nextAnnotation"></div>
                  </div>
                </div>
                <div class="origin-text">{{annoDialogOriginText}}</div>
              </div>
              <div class="anno-text-list">
                <div class="annotations-title font1">批注</div>
                <div class="anno-list-area" ref="annoListArea">
                  <div class="annotations-item" v-for="item in annoDialogList" :key="item.contId">
                    <div class="annotations-text">{{item.content}}</div>
                    <div class="annotations-info">
                      <div class="anno-info-name">{{item.userName}}</div>
                      <div class="anno-info-time">{{item.updateTime}}</div>
                      <div class="anno-info-type">{{item.type === 1 ? '公开' : '私密'}}</div>
                      <div class="annotations-operation" v-if="item.userId == userId">
                        <div class="anno-operatio-update" @click="goToAnnotationEdit(item)">修改</div>
                        <div class="anno-operatio-delete" @click="delPostil(item)">删除</div>
                      </div>
                      <div class="annotations-operation" v-if="item.userId !== userId"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="add-annotation">
                <div class="add-annotation-btn" @click="goToAnnotationEdit()">增加批注</div>
              </div>
            </div>
        </el-drawer>
      </div>
      <div class="pdf-document-outline">
        <el-drawer 
          :visible.sync="showDocumentOutline"
          :direction="drawerDirection"
          size="80%"
          @opened="outlineDrawerOpened"
          :with-header="false">
            <div class="drawer-hearder">
              <div class="hearder-first"></div>
              <div class="drawer-title">
                <span class="title-text">文档大纲</span>
              </div>
              <div class="drawer-close">
                <div class="drawer-close-icon" @click="closeDrawer"></div>
              </div>
              <div class="hearder-last"></div>
            </div>
            <div id="outlineViewr"></div>
        </el-drawer>
      </div>
      <div class="annotations-edit">
        <el-drawer 
          :visible.sync="showAnnotionEdit"
          :direction="drawerDirection"
          :before-close="handleAnnoEditClose"
          size="20%"
          :with-header="false">
            <div class="anno-edit-type">
              <el-radio-group v-model="annotationType">
                <el-radio :label="true">公开</el-radio>
                <el-radio :label="false">私密</el-radio>
              </el-radio-group>
            </div>
            <div class="anno-edit-content">
              <div class="anno-edit-create">
                <input type="text" class="anno-edit-input" v-model="annotationsInput" @touchstart.stop="notPropagation">
              </div>
              <div class="addAnnoBtn" @click="submitPostil">确定</div>
            </div>
        </el-drawer>
      </div>
    </div>
  </div>
</template>
<script>
  import * as pdfjs from 'pdfjs-dist/es5/build/pdf.js';
  import * as newViewer from '../../../node_modules/pdfjs-dist/es5/web/pdf_viewer';
  import {PDFRenderingQueue} from '@/utils/PDF/pdf_rendering_queue.js';
  import {PDFOutlineViewer} from '@/utils/PDF/pdf_outline_viewer.js';
  import {getViewerConf} from '@/utils/PDF/appConfig.js';
  import {PDFSidebar} from '@/utils/PDF/pdf_sidebar.js';
  import {RendererType, animationStarted} from '@/utils/PDF/ui_utils.js';
  import {PDFFindController} from '@/utils/PDF/pdf_find_controller.js';
  import Highlighter from 'web-highlighter';
  import {
    savePdfAnnotation,
    delPdfAnnotation,
    getPdfAnnotation,
    getPdfByBase64,
    getPdfOnlineTranslate,
    editPdfAnnotation
  } from '@/utils/getPdfData';

  pdfjs.GlobalWorkerOptions.workerSrc = require('pdfjs-dist/es5/build/pdf.worker.entry.js');

  export default {
    name: 'PdfView',
    data () {
      return {
        loading: true,
        appConfig: null,
        pdfDocument: null,
        pdfLoadingTask: null,
        pdfViewer: null,
        pdfRenderingQueue: null,
        pdfLinkService: null,
        pdfHistory: null,
        pdfSidebar: null,
        pdfOutlineViewer: null,
        eventBus: null,
        l10n: null,
        annotionArray: [],
        annotionAll: [],
        annoDialogList: [],
        editAnnotationItem: null,
        highlighterId: '',
        highlighter: null,
        currentHighlighter: null,
        annotationsInput: '',
        currentPageNum: 1,
        findStat: true,
        selection: null,
        selectionText: '',
        artcleId: 776,
        userId: 1312,
        searchKey: '',
        hasSearch: false,
        baseUrl: '',
        autoTranslation: '',
        drawerDirection: 'btt',
        showDocumentOutline: false,
        showAnnotionEdit: false,
        showAnnotionList: false,
        annotationType: false,
        annoDialogOriginText: '',
        findStatus: false,
        dialogMenuBoxShow: false,
        dialogTranslateBoxShow: false,
        isSelectTime: null,
        onepageTime: null,
        annotationsActionType: 'new',
        touchPageNumbers: 0,
        annotionInput: false,
        isAndroid: false,
        requestConfig: {
          headers: {
            'Content-type': 'application/json',
            'Authorization': ''
          }
        }
      };
    },
    beforeCreate () {
      document.title = this.$route.query.filename || '文件详情';
    },
    mounted () {
      try {
        this.judgeIsAndroid();
        this.initQueryParams();
        this.getBase64();
        this.initHighLighter();
        this.disableContextMenu();
      } catch (err) {
        this.$message(err);
      }
    },
    beforeDestroy () {
      this.showAnnotionList = false;
      this.showDocumentOutline = false;
      this.showAnnotionEdit = false;
      this.annotationType = false;
      this.hasSearch = false;
      this.eventClearDialog();
    },
    destroyed () {
      this.eventClearDialog();
      this.offEvents();
      this.close();
    },
    methods: {
      judgeIsAndroid () {
        const userAgent = typeof navigator !== "undefined" && navigator.userAgent || "";
        this.isAndroid = /Android/.test(userAgent);
      },
      disableContextMenu () {
        window.addEventListener('contextmenu', function(e){  
          e.preventDefault();  
        });
      },
      initQueryParams () {
        const {id, page, token, userid, searchWord} = this.$route.query;
        this.artcleId = id ? parseInt(id) : 731;
        // this.artcleId = 776; // 因为测试服务器没有文件临时固定写死的
        this.currentPageNum = page ? parseInt(page) === 0 ? 1 : parseInt(page) : 1;
        this.userId = userid ? parseInt(userid) : this.userId;
        this.searchKey = searchWord ? searchWord.trim() : '';
        this.hasSearch = searchWord ? true : false;
        let reqToken = token ? token : 'Manual settings'
        this.requestConfig.headers.Authorization = 'Bearer ' + reqToken;
      },
      getBase64 () {
        this.initViewer();
        getPdfByBase64(this, {
          "data": {
            "id": this.artcleId
          }
        }, this.requestConfig).then((res) => {
          if (res.code === 0) {
            this.open('data:application/pdf;base64,'+res.data);
          } else {
            this.$message.error('网络不稳定,请稍后重试');
          }
        }).finally(() => {
          this.loading = false;
          if (this.searchKey) {
            setTimeout(() =>{
              this._handleInitFind(this.searchKey);
            },1)
          }
        });
      },
      initViewer () {
        this.appConfig = getViewerConf();

        const eventBus = new newViewer.EventBus();
        this.eventBus = eventBus;

        const pdfRenderingQueue = new PDFRenderingQueue();
        pdfRenderingQueue.onIdle = this.cleanup.bind(this);
        this.pdfRenderingQueue = pdfRenderingQueue;

        const pdfLinkService = new newViewer.PDFLinkService({
          eventBus,
          externalLinkTarget: 0,
          externalLinkRel: 'noopener noreferrer nofollow',
          ignoreDestinationZoom: true
        });
        this.pdfLinkService = pdfLinkService;

        this.l10n = newViewer.NullL10n;

        const findController = new PDFFindController({
          linkService: pdfLinkService,
          eventBus,
          PDF: pdfjs
        });
        this.findController = findController;

        const container = this.$refs.viewerContainer;
        const viewer = this.$refs.pdfContainer;
        this.pdfViewer = new newViewer.PDFViewer({
          container,
          viewer,
          eventBus,
          renderingQueue: pdfRenderingQueue,
          linkService: pdfLinkService,
          findController,
          renderer: 'canvas',
          l10n: this.l10n,
          textLayerMode: 3,
          imageResourcesPath: './images/',
          maxCanvasPixels: 5242880
        });

        pdfRenderingQueue.setViewer(this.pdfViewer);
        pdfLinkService.setViewer(this.pdfViewer);

        this.pdfHistory = new newViewer.PDFHistory({
          linkService: pdfLinkService,
          eventBus
        });
        pdfLinkService.setHistory(this.pdfHistory);

        this.pdfOutlineViewer = new PDFOutlineViewer({
          container: document.getElementById('outlineView'),
          eventBus,
          linkService: pdfLinkService,
          PDF: pdfjs
        });

        this.pdfSidebar = new PDFSidebar({
          elements: this.appConfig.sidebar,
          pdfViewer: this.pdfViewer,
          eventBus,
          l10n: this.l10n
        });
        this.pdfSidebar.onToggled = this.forceRendering.bind(this);
        this.pdfSidebar.open();

        this.bindEvents();
        this.monitorViewTouchEvent();
      },
      async close () {
        if (!this.pdfLoadingTask) {
          return undefined;
        }
        const promise = this.pdfLoadingTask.destroy();
        this.pdfLoadingTask = null;
        if (this.pdfDocument) {
          this.pdfDocument = null;
          this.pdfViewer.setDocument(null);
          this.pdfLinkService.setDocument(null);
        }
        this.pdfSidebar.reset();
        this.pdfOutlineViewer.reset();
        if (this.pdfHistory) {
          this.pdfHistory.reset();
        }
        return promise;
      },
      async open (_data) {
        this.baseUrl = _data;
        if (this.pdfLoadingTask) {
          await this.close();
        }
        const loadingTask = pdfjs.getDocument({
          url: _data,
          cMapUrl: 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.4.456/cmaps/',
          cMapPacked: true,
          disableAutoFetch: false,
          disableFontFace: false,
          disableRange: false,
          disableStream: false,
          fontExtraProperties: false,
          isEvalSupported: true,
          maxImageSize: -1,
          pdfBug: false,
          verbosity: 1
        });
        this.pdfLoadingTask = loadingTask;
        return loadingTask.promise.then(
          pdfDocument => {
            this.load(pdfDocument);
          },
          exception => {
            if (loadingTask !== this.pdfLoadingTask) {
              return undefined; // Ignore errors for previously opened PDF files.
            }
          }
        );
      },
      load (pdfDocument) {
        this.pdfDocument = pdfDocument;
        const pageLayoutPromise = pdfDocument.getPageLayout().catch(function () {
          /* Avoid breaking initial rendering; ignoring errors. */
        });
        const pageModePromise = pdfDocument.getPageMode().catch(function () {
          /* Avoid breaking initial rendering; ignoring errors. */
        });
        const openActionPromise = pdfDocument.getOpenAction().catch(function () {
          /* Avoid breaking initial rendering; ignoring errors. */
        });
        // this.toolbar.setPagesCount(pdfDocument.numPages, false);
        this.pdfLinkService.setDocument(pdfDocument, null);

        const pdfViewer = this.pdfViewer;
        pdfViewer.setDocument(pdfDocument);
        const {firstPagePromise, onePageRendered} = pdfViewer;
        firstPagePromise.then(pdfPage => {
          Promise.all([
            animationStarted,
            // storedPromise,
            pageLayoutPromise,
            pageModePromise,
            openActionPromise
          ])
            .then(async ([timeStamp, pageLayout, pageMode, openAction]) => {
              this.eventBus.dispatch('documentinit', {source: this});
              this._handleInitPage(this.currentPageNum);
              // if (this.searchKey) {
              //   setTimeout(() =>{
              //     this._handleInitFind(this.searchKey);
              //   },1)
              // }
            })
            .catch(() => {
            })
            .then(function () {
              pdfViewer.update();
            });
        });
        onePageRendered.then(() => {
          pdfDocument.getOutline().then(outline => {
            this.pdfOutlineViewer.render({outline});
          });
          this.onepageTime = setTimeout(() => {
            this.resetSpanTransform();
            this.findAllDB(this.currentPageNum);
            clearTimeout(this.onepageTime);
            this.onepageTime = null;
          },500)
        });
      },
      submitPostil () {
        if (this.annotationsInput.length === 0) {
          this.$message('请填写批注内容');
          return false;
        }
        this.annotionInput = false;
        if (this.annotationsActionType === 'edit') {
          this.currentHighlighter = this.editAnnotationItem;
          this.currentHighlighter.content = this.annotationsInput;
          this.currentHighlighter.type = this.annotationType; // false 私密 true 公开
          this.editDB(this.currentHighlighter);
        } else {
          if (this.editAnnotationItem) {
            this.currentHighlighter = this.editAnnotationItem;
          } else {
            this.currentHighlighter.artcleId = parseInt(this.artcleId);
            this.currentHighlighter.pageNum = this.currentPageNum;
          }
          this.currentHighlighter.content = this.annotationsInput;
          this.currentHighlighter.type = this.annotationType; // false 私密 true 公开
          this.currentHighlighter.contId = new Date().getTime() + '' + (Math.floor(Math.random() * (999 - 100)) + 100);
          this.insertDB(this.currentHighlighter);
        }
      },
      delPostil (item) {
        this.$confirm('确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.removeDB(parseInt(item.contId));
        }).catch(() => {
        });
      },
      insertDB (obj) {
        this.loading = true;
        let reqObj = {
          startMeta: obj.startMeta,
          endMeta: obj.endMeta,
          text: obj.text,
          id: obj.id,
          pageNum: parseInt(obj.pageNum),
          artcleId: parseInt(obj.artcleId),
          content: obj.content,
          type: obj.type ? 1 : 0,
          contId: parseInt(obj.contId),
          userId: parseInt(this.userId)
        };
        savePdfAnnotation(this, {Data: reqObj}, this.requestConfig).then((res) => {
          if (res.code === 0) {
            this.$message('批注成功');
            this.saveEditClear();
          } else {
            this.$message('批注失败');
          } 
        }).catch((err) => {
          this.$message('网络不稳定,请稍后重试');
        }).finally(() => {
          this.loading = false;
        });
      },
      editDB (obj) {
        this.loading = true;
        let reqObj = {
          contId: parseInt(obj.contId),
          content: obj.content,
          type: obj.type ? 1 : 0,
        }
        editPdfAnnotation(this, {Data: reqObj}, this.requestConfig).then((res) => {
          if (res.code === 0) {
            this.$message('修改批注成功');
            this.saveEditClear();
          } else {
            this.$message('修改批注失败');
          } 
        }).catch((err) => {
          this.$message('网络不稳定,请稍后重试');
        }).finally(() => {
          this.loading = false;
        });
      },
      saveEditClear () {
        this.annotationsInput = '';
        this.selection = null;
        this.selectionText = null;
        this.showAnnotionEdit = false;
        this.currentHighlighter = null;
        this.findAllDB(this.currentPageNum);
      },
      // 获取每一页的批注信息
      findAllDB (page) {
        if (this.findStatus) {
          return false;
        }
        this.loading = true;
        this.findStatus = true;
        this.highlighter.removeAll();
        getPdfAnnotation(this, {
          Data: {
            artcleId: parseInt(this.artcleId),
            userId:  parseInt(this.userId),
            pageNum: page
          }
        }, this.requestConfig).then((res) => {
          if (res.code === 0) {
            const arr = res.data || [];
            if (arr.length > 0) {
              let idArr = [];
              arr.forEach(item => {
                let obj = {};
                obj.id = item.id;
                obj.text = item.text;
                obj.startMeta = item.startMeta;
                obj.endMeta = item.endMeta;
                idArr.push(obj);
              });
              this.annotionArray = this.unique(idArr);
              this.annotionAll = arr;
              this.reviewAnnotation()
            } else {
              this.annotionArray = [];
              this.annotionAll = [];
              // this.$message.warning('当前页暂无批注');
            }
          } else {
            this.$message.error('网络不稳定,请稍后重试');
          }
        }).finally(() => {
          this.findStatus = false
          this.loading = false;
        });
      },
      unique (songs) {
        let result = {};
        let finalResult = [];
        for (let i = 0; i < songs.length; i++) {
          result[songs[i].id] = songs[i];
        }
        for (let item in result) {
          finalResult.push(result[item]);
        }
        return finalResult;
      },
      reviewAnnotation () {
        this.annotionArray.forEach(item => {
          this.highlighter.fromStore(item.startMeta, item.endMeta, item.text, item.id);
        });
      },
      removeDB (rid) {
        this.loading = true;
        delPdfAnnotation(this, {Data: {contId: rid}}, this.requestConfig).then(res => {
          let resStat = res.code === 0 ? '已删除' : '删除失败';
          this.$message(resStat);
          if (res.code === 0) {
            this.showAnnotionList = false;
            this.findAllDB(this.currentPageNum);
          }
        }).catch((err) => {
          this.$message('网络不稳定,请稍后重试');
        }).finally(() => {
          this.loading = false;
        });
      },
      monitorViewTouchEvent () {
        this.$refs.pdfContainer.addEventListener('touchstart', e => {
          let parentNodeId = e.target.parentNode.parentNode.getAttribute('data-page-number');
          if (!parentNodeId) {
            parentNodeId = e.target.parentNode.parentNode.parentNode.getAttribute('data-page-number');
            if (!parentNodeId) {
              parentNodeId = e.target.parentNode.getAttribute('data-page-number');
            }
          }
          this.touchPageNumbers = parseInt(parentNodeId);
        },{passive: false});

        document.addEventListener("selectionchange", e => {
          if (!this.annotionInput) {
            const selection = window.getSelection();
            if (selection.toString()) {
              this.selection = selection.getRangeAt(selection.rangeCount-1);
              this.selectionText = this.selection.toString();
              this.judgeSelection();
            } else {
              this.eventClearDialog();
            }
          }
        });

        this.$refs.viewerContainer.addEventListener('scroll', e => {
          this.eventClearDialog();
          e.preventDefault();
        },{passive: false});
      },
      eventClearDialog () {
        this.dialogMenuBoxShow = false;
        this.dialogTranslateBoxShow = false;
        window.getSelection().removeAllRanges();
        this.selection = null;
      },
      judgeSelection () {
        if (this.touchPageNumbers !== this.currentPageNum) {
          this.$message.info('划词失效,请在当前页面划词');
          return false;
        }
        this.showMenu()
      },
      notPropagation (evt) {
        this.annotionInput = true;
        evt.stopPropagation();
      },
      showMenu () {
        const pageEl = document.querySelector('.page');
        const countPageTop = (this.currentPageNum - 1) * parseInt(pageEl.style.height);
        let pageMargin = 10;
        if (this.currentPageNum > 1) {
          pageMargin = (this.currentPageNum - 1) * 23; 
        }
        let parentElement = this.selection.endContainer.parentElement;
        if (this.selection.endContainer.parentNode.tagName.toUpperCase() === 'I') {
          parentElement = this.selection.endContainer.parentElement.parentElement;
        }
        const endSpanLeft = parseInt(parentElement.style.left);
        const endSpanTop = parseInt(parentElement.style.top);
        const endSpanSize = parseInt(parentElement.style.fontSize);
        const windowWidth = parseInt(pageEl.style.width);
        let elLeft = endSpanLeft + (this.selection.startOffset * endSpanSize);
        if ((elLeft + 198) > windowWidth) {
          elLeft = windowWidth - 198;
        }
        this.$refs.dialogMenuBox.style.top = (countPageTop + endSpanTop + endSpanSize + pageMargin + 30 - this.$refs.viewerContainer.scrollTop) + 'px';
        this.$refs.dialogMenuBox.style.left = elLeft + 'px';
        this.dialogMenuBoxShow = true;
      },
      // 批注
      judgeSelectionExistAnno () {
        this.dialogMenuBoxShow = false;
        let haveAnno = this.annotionArray.some((item) => {
          return item.text === this.selectionText;
        })
        this.annoDialogOriginText = this.selectionText;
        if (haveAnno) {
          this.showAnnotionList = true;
          window.getSelection().removeAllRanges();
          this.annoDialogList = [];
          for(let i = this.annotionAll.length - 1; i > -1; i--) {
            if (this.annotionAll[i].text === this.selectionText) {
              if (parseInt(this.userId) === parseInt(this.annotionAll[i].userId)) {
                this.annoDialogList.push(this.annotionAll[i]);
              } else if (parseInt(this.userId) !== parseInt(this.annotionAll[i].userId) && parseInt(this.annotionAll[i].type) === 1) {
                this.annoDialogList.push(this.annotionAll[i]);
              }
            }
          }
        } else {
          this.highlighter.fromRange(this.selection);
          this.editAnnotationItem = null;
          this.annotationsInput = '';
          this.showAnnotionEdit = true;
          this.annotationType = false;
          this.annotationsActionType = 'new';
        }
      },
      // 翻译
      translateText (e) {
        this.loading = true;
        const isContainChinese = new RegExp('[\\u4E00-\\u9FFF]+', 'g');
        const obj = {};
        obj.textBlock = this.selectionText.match(/[\S]*/g).filter(item => item).join(' ');
        if (isContainChinese.test(obj.textBlock)) {
          obj.sourceLang = 'zh';
          obj.targetLang = 'en';
        } else {
          obj.sourceLang = 'en';
          obj.targetLang = 'zh';
        }
        getPdfOnlineTranslate(this, {data: obj}, this.requestConfig).then(res => {
          if (res.code === 0) {
            this.autoTranslation = res.data.autoTranslation;
            const bodyWidth = this.$refs.pdfViewerBox.clientWidth;
            const tDialogWidth = document.querySelector('.textLayer').style.width;
            this.dialogTranslateBoxShow = true;
            this.dialogMenuBoxShow = false;
            this.$refs.dialogTranslateBox.style.width = tDialogWidth;
            this.$refs.dialogTranslateBox.style.left = (bodyWidth - parseInt(tDialogWidth) -2)/2 + 'px';
            this.$refs.dialogTranslateBox.style.top = (e.clientY + 10) + 'px';
            if ((e.clientY + 150) > this.$refs.viewerContainer.clientHeight) {
              this.$refs.dialogTranslateBox.style.top = '100px';
            }
          }
        }).catch((err) => {
          this.$message('网络不稳定,请稍后重试');
        }).finally(() => {
          this.loading = false;
        });
      },
      // 复制
      copySelected () {
        // if (navigator && navigator.clipboard) {
        //   navigator.clipboard.writeText(this.selectionText).then(() => {
        //     this.$message('复制成功');
        //   });
        // } else {
          this.$copyText(this.selectionText).then(e => {
            this.$message('复制成功');
          }, e => {
            this.$message('复制失败');
          })
        // }
      },
      // 目录
      openDocumentoutline () {
        this.showDocumentOutline = !this.showDocumentOutline;
      },
      findKeyword () {
        if (this.searchKey) {
          setTimeout(() =>{
            this._handleInitFind(this.searchKey);
          },1)
        }
      },
      outlineDrawerOpened () {
        const outlineViewr = document.querySelector('#outlineViewr');
        outlineViewr.appendChild(document.querySelector('#outlineView'))
      },
      closeDrawer () {
        this.showAnnotionList = false;
        this.showDocumentOutline = false;
      },
      handleAnnoEditClose () {
        this.annotionInput = false;
        this.showAnnotionEdit = false;
        this.annotationType = false;
        this.annotationsInput = '';
        this.highlighter.remove(this.highlighterId);
      },
      goToAnnotationEdit (item) {
        this.annotationsActionType = item ? 'edit' : 'new';
        this.$refs.annotationDrawer.closeDrawer();
        this.annotationsInput = item ? item.content : '';
        this.editAnnotationItem = item ? item : this.annoDialogList[0];
        this.annotationType = item ? item.type === 1 ? true : false : false;
        this.showAnnotionEdit = true;
      },
      previousAnnotation () {
        const highlighterId = this.annoDialogList[0].id;
        for (let i=0; i<this.annotionArray.length; i++) {
          if (this.annotionArray[i].id == highlighterId) {
            if (i == 0) {
              this.$message.warning('已是第一个');
            }
            if (i > 0) {
              this.setCurrentAnnoDialogList(this.annotionArray[i-1].id);
            }
            break;
          }
        }
      },
      nextAnnotation () {
        const highlighterId = this.annoDialogList[0].id;
        for (let i=0; i<this.annotionArray.length; i++) {
          if (this.annotionArray[i].id == highlighterId) {
            if (i == this.annotionArray.length -1) {
              this.$message.warning('已是最后一个');
            }
            if (i < this.annotionArray.length -1) {
              this.setCurrentAnnoDialogList(this.annotionArray[i+1].id);
            }
            break;
          }
        }
      },
      setCurrentAnnoDialogList (preHighlighterId) {
        this.annoDialogList = [];
        for(let i = this.annotionAll.length - 1; i > -1; i--) {
          if (this.annotionAll[i].id === preHighlighterId) {
            if (parseInt(this.userId) === parseInt(this.annotionAll[i].userId)) {
              this.annoDialogList.push(this.annotionAll[i]);
            }
            if (parseInt(this.userId) !== parseInt(this.annotionAll[i].userId) && parseInt(this.annotionAll[i].type) === 1) {
              this.annoDialogList.push(this.annotionAll[i]);
            }
          }
        }
        this.annoDialogOriginText = this.annoDialogList[0].text;
      },
      // 以下是标注
      initHighLighter () {
        let self = this;
        let hiRoot = this.$refs.pdfContainer;
        const highlighter = new Highlighter({
          $root: hiRoot,
          wrapTag: 'i',
          style: {
            className: 'highlight-wrap-new'
          }
        });
        this.highlighter = highlighter;
        highlighter
          .on(Highlighter.event.CREATE, (data, inst, e) => {
            self.currentHighlighter = data.sources[0];
            self.currentHighlighter.pageNum = self.currentPageNum;
            self.highlighterId = self.currentHighlighter.id;
          })
          .on(Highlighter.event.CLICK, (data, inst, e) => {});
        highlighter.stop();
      },

      cleanup () {
        if (!this.pdfDocument) {
          return; // run cleanup when document is loaded
        }
        this.pdfViewer.cleanup();
        // this.pdfThumbnailViewer.cleanup();
        // We don't want to remove fonts used by active page SVGs.
        if (this.pdfViewer.renderer !== RendererType.SVG) {
          this.pdfDocument.cleanup();
        }
      },

      webViewerPageChanging (evt) {
        const page = evt.pageNumber;
        this.currentPageNum = page;
        this.resetSpanTransform();
        this.findAllDB(this.currentPageNum); 
      },
      webViewerPagesinit (evt) {
        this.pdfViewer.currentScaleValue = 'page-width';
      },
      resetSpanTransform () {
        if (this.isAndroid) {
          const spalist = document.querySelector(`[data-page-number="${this.currentPageNum}"]`).querySelector('.textLayer').childNodes;
          for (let i=0; i<spalist.length; i++) {
            if (spalist[i].tagName.toLowerCase() !== 'span' || !spalist[i].style.transform) {
              continue;
            }
            const fontSizeSpan = parseFloat(spalist[i].style.fontSize);
            const transformSpan = parseFloat(spalist[i].style.transform.match(/\d+(.\d+)?/g));
            if (fontSizeSpan > 8) {
              continue;
            }
            if (transformSpan.toString().length === 4) {
              break;
            }
            const transformNum = ((fontSizeSpan * transformSpan) / 8).toFixed(2);
            spalist[i].style.transform = `scaleX(${transformNum})`;
          }
        }
      },
      _handleInitPage (val) {
        this.pdfViewer.currentPageNumber = Number(val) || 1;
      },
      _handleInitFind (query) {
        this.findController.executeCommand('find', {
          query: query,
          phraseSearch: true,
          caseSensitive: false,
          entireWord: true,
          highlightAll: true,
          findPrevious: false
        });
      },
      forceRendering () {
        this.pdfRenderingQueue.renderHighestPriority();
      },
      bindEvents () {
        const {eventBus} = this;
        eventBus.on("pagesinit", this.webViewerPagesinit);
        eventBus.on('pagechanging', this.webViewerPageChanging);
      },
      offEvents () {
        const {eventBus} = this;
        eventBus.off("pagesinit", this.webViewerPagesinit);
        eventBus.off('pagechanging', this.webViewerPageChanging);
      }
    }
  };
</script>

<style>
  @import './viewer.css'; /*引入公共样式*/
  #pdf-viewer-box {
    width: 100%;
    height: 100%;
  }
  .highlight-wrap-new {
    position: relative;
    font-style: normal !important;
  }

  .highlight-wrap-new::before {
    content: '';
    width: 100%;
    text-decoration-line: underline;
    text-decoration-color: red;
    position: absolute;
    bottom: -1px;
    left: 0px;
    padding: 1px 0px;
    background-image: url('./images/under-line.svg');
    background-size: 6px 2px;
    background-position: 0 100%;
    background-repeat: repeat-x;
  }

  #dialog-menu-box {
    position: absolute;
    z-index: 103;
  }
  .dialog-menu {
    float: left;
    width: 66px;
    height: 36px;
    border-radius: 3px;
    background: #fcebe9;
    line-height: 36px;
    text-align: center;
    color: #db3721;
    font-size: 20px;
    font-weight: 600;
    user-select: none;
    pointer-events: auto;
  } 
  #dialog-translate-box {
    position: absolute;
    background: rgb(252, 235, 233);
    box-shadow: 1px 1px 1px #f7d4d4;
    padding: 10px;
    z-index: 102;
    margin-top: 4px;
    user-select: none;
    box-sizing: border-box;
    max-height: 150px;
    overflow-y: auto;
  }
  #dialog-translate-box>div {
    margin-bottom: 10px;
    color: rgb(219, 55, 33);
    user-select: none;
    word-break: break-all;
    pointer-events: auto;
  }
  #dialog-translate-box>div:nth-child(2) {
    margin-bottom: 0px;
  }

  .outline-btn {
    position: fixed;
    top: 60%;
    z-index: 101;
    color: #fff;
    text-align: center;
    font-size: 24px;
    line-height: 36px;
    cursor: pointer;
    width: 78px;
    height: 36px;
    border-radius: 6px;
    opacity: 1;
    background: linear-gradient(270deg, #e48275ff 0%, #eecdc9ff 100%);
  }
  .search-btn {
    top: 80% !important;
  }
  .drawer-hearder {
    display: flex;
    height: 70px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('./images/drawer-hearder.svg');
  }
  .hearder-first {
    height: 16px;
    width: 20px;
    margin-top: 28px;
    border-right: 3px solid #d92222;
  }
  .drawer-title {
    flex: 1;
    padding-left: 5px;
    line-height: 70px;
    font-size: 18px;
  }
  .drawer-close {
    flex: 1;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
  .drawer-close-icon {
    height: 18px;
    width: 18px;
    background-image: url('./images/close16X16PX.svg');
    cursor: pointer;
  }
  .hearder-last {
    width: 20px;
  }
  #outlineViewr {
    position: relative;
    height: calc(100% - 70px);
  }
  .anno-origin-text {
    border-bottom: 1px solid #ccc;
    height: 80px;
    padding: 0px 20px;
  }
  .anno-text-list {
    height: calc(100% - 80px);
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
  }
  .annotations-title {
    height: 45px;
    padding: 0px 20px;
    line-height: 52px;
  }
  .anno-list-area {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px 20px 115px 20px;
  }
  .origin-text-operation {
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .anno-origin-text .origin-text {
    height: 35px;
    line-height: 35px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #999;
    font-size: 15px;
  }
  .switch-annotations {
    display: flex;
    width: 50px;
  }
  .switch-annotations>div {
    width: 14px;
    height: 14px;
    background-repeat: no-repeat;
    cursor: pointer;
  }
  .select-left {
    background-image: url('./images/left-arrow.svg');
  }
  .select-right {
    background-image: url('./images/right-arrow.svg');
    background-position-x: right;
  }
  .select-left:active {
    opacity: 0.6;
  }
  .select-right:active {
    opacity: 0.6;
  }
  .annotations-text {
    height: 30px;
    line-height: 30px;
  }
  .annotations-info {
    height: 30px;
  }
  .annotations-info>div {
    color: #999;
    height: 30px;
    line-height: 30px;
  }
  .annotations-info .anno-info-name {
    width: 72px;
    float: left;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .annotations-info .anno-info-time,
  .annotations-info .anno-info-type {
    float: left;
    font-size: 15px;
    margin-left: 5px;
  }
  .annotations-operation {
    float: right;
    right: 20px;
  }
  .annotations-operation>div {
    cursor: pointer;
    float: left;
    color: #0052D9;
    font-size: 15px;
    line-height: 30px;
  }
  .anno-operatio-update {
    margin-right: 6px;
  }
  .add-annotation {
    position: fixed;
    bottom: 0px;
    width: 100%;
    background-color: #fff;
  }
  .add-annotation-btn {
    width: 98%;
    margin: 0 auto;
    height: 40px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    color: #666;
    background-color: #F5C7C0;
    border: 1px solid #F5C7C0;
    border-radius: 10px;
  }
  .add-annotation-btn:active {
    opacity: 0.8;
  }
  .add-annotation-btn:hover {
    background: #F1B3AB;
    border-color: #F1B3AB;
  }
  .anno-edit-type {
    height: 50%;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
    padding: 0px 20px;
  }
  .anno-edit-content {
    height: 50%;
    display: flex;
    align-items: center;
    padding: 0px 20px;
  }

  .anno-edit-create {
    flex: 1;
  }
  .anno-edit-create .anno-edit-input{
    height: 40px;
    border-radius: 6px;
    width: 100%;
    background-color: #F2F2F2;
  }
  .addAnnoBtn {
    width: 80px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    color: #fff;
    background-color: #DB3721;
    border-radius: 6px;
    margin-left: 20px;
  }
  .addAnnoBtn:active ,
  .el-message-box__wrapper .el-message-box .el-message-box__btns .el-button--primary:active {
    opacity: 0.8;
  }
  .addAnnoBtn:hover {
    background: #d82e18;
  }
  .el-drawer {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  .el-drawer__body {
    overflow: hidden !important;
  }
  .font1 {
    font-size: 18px;
  }
  .el-radio-group .el-radio__inner {
    width: 20px;
    height: 20px;
  }
  .el-radio-group .el-radio__inner::after {
    width: 12px;
    height: 12px;
  }
  .el-radio-group .is-checked .el-radio__inner {
    border-color: #DB3721 !important;
    background: #fff !important;
  }
  .el-radio-group .is-checked .el-radio__inner::after {
    background-color:#DB3721;
  }
  .el-message-box__wrapper .el-message-box {
    width: 80% !important;
  }
  .el-message-box__wrapper .el-message-box .el-message-box__btns .el-button--primary{
    background-color: #db3721 !important;
    border-color: #db3721 !important;
  }
  .el-message-box__wrapper .el-message-box .el-message-box__btns .el-button--primary:hover{
    background-color: #db3721 !important;
    border-color: #db3721 !important;
  }
  .el-message-box__wrapper .el-message-box .el-message-box__btns .el-button--primary>span {
    color: #fff !important;
  }
  #viewerContainer .textLayer .highlight.selected {
    background: rgba(219, 55, 33, 0.2) !important;
    opacity: 1;
  }
</style>
