// get PDF base64
export const getPdfByBase64 = (app, obj, config) => app.$http.post('/api/read/resource/resource-load', obj, config);
// save PDF annotation
export const savePdfAnnotation = (app, obj, config) => app.$http.post('/api/read/comment/save-comment', obj, config);
// get PDF annotation
export const getPdfAnnotation = (app, obj, config) => app.$http.post('/api/read/comment/query-comment', obj, config);
// delete PDF annotation
export const delPdfAnnotation = (app, obj, config) => app.$http.post('/api/read/comment/remove-comment', obj, config);
// get pdf stream
// export const getPdfByStream = (app, obj) => app.$http.post('/read/kid_file/kid-load-stream', obj)
export const getPdfByStream = (app, obj, config) => app.$http.post('/api/read/resource/new-resource-load', obj, config);
// get PDF annotation pageNumbers
export const getPdfAnnotationPageNums = (app, obj, config) => app.$http.post('/api/read/comment/query-comment-page-num-list', obj, config);
// get translate text
export const getPdfOnlineTranslate = (app, obj, config) => app.$http.post('/api/read/resource/online-translate', obj, config);
// edit PDF annotation
export const editPdfAnnotation = (app, obj, config) => app.$http.post('/api/read/comment/edit-comment', obj, config);
