const report = {
  // 获取Token
  getUserToken () {
    return localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).token : '';
  },
  // 获取Uin
  getUserId () {
    return localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).userId : '';
  },
  getUserName () {
    return localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).username : '';
  },
  getPath () {
    return 'https://www.fatfreader.com';
  },
  sendReport (reportActionData) {
    try {
      // 添加token和url
      const params = Object.assign({}, reportActionData);
      reportActionData = params;
      const url = `${this.getPath()}/account/data/add-data-log`;
      const xmlHttp = new XMLHttpRequest();
      // 2、绑定监听 ---- 监听服务器是否已经返回相应数据
      xmlHttp.onreadystatechange = function () {
        if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
          console.log('数据埋点请求结果：', xmlHttp.responseText);
        }
      };
      // 3、绑定地址 （第一个参数是请求方式，可以自己写成GET,或者POST）
      xmlHttp.open('POST', url, true);
      // 4、发送请求
      xmlHttp.setRequestHeader('authorization', 'Bearer ' + this.getUserToken());
      xmlHttp.setRequestHeader('Content-type', 'application/json');
      xmlHttp.send(JSON.stringify({data: reportActionData}));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }
};
export default report;
