import { Message } from 'element-ui';

import { router } from '../router';

import axios from "axios";

// 开发环境
// axios.defaults.baseURL = '/';
// 测试
// axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? '/' : 'https://index.fatf.tenfell.cn';
// 生产环境
axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? 'https://wx.fatfreader.com' : 'https://wx.fatfreader.com';
axios.defaults.timeout = 100000000 * 1000;

axios.interceptors.response.use(
  response => {
    if (response.data.code === 401 && router.history.current.path !== '/login') {
      Message({
        message: '暂无权限/账号重复登录，请重新登录',
        type: 'warning',
        duration: 3 * 1000,
        customClass: 'messageIndex'
      });
      return false;
    }
    return response.data;
  },
  error => {
    return Promise.reject(error);
  }
);

export default axios;
